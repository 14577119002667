import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import women from "../imgs/women.png";
import free from "../imgs/free.png";
import premium from "../imgs/diamond.png";
import money from "../imgs/money.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import { BsPeople } from "react-icons/bs";
import { MdWorkspacePremium } from "react-icons/md";
import { GiTakeMyMoney } from "react-icons/gi";
import { AiFillEdit } from "react-icons/ai";
import { LuView } from "react-icons/lu";
import CircularProgress from "@mui/joy/CircularProgress";
// import Modal from "@mui/material/Modal";
import Modal from "react-modal";
import DotLoader from "react-spinners/DotLoader";
import {
  getCounters,
  TU,
  FU,
  PU,
  TI,
  getUsers,
  usersList,
  current,
  total,
  pageLim,
  loadIndicator,
  setAccountToPremium,
} from "../reducers/admin";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Home = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const listUsers = useSelector(usersList);
  const presentPage = useSelector(current);
  const totlpage = useSelector(total);
  const pageLimit = useSelector(pageLim);
  const loading = useSelector(loadIndicator);
  const [namefilter, setNameFilter] = useState("");
  const [lastPaymentDate, setLastPaymentDate] = useState("");
  const [nextPayment, setNextPayment] = useState("");
  const [userId, setUserId] = useState("");
  const [initialsub, setInitialSub] = useState("");
  const setdata = {
    last_payment_date: lastPaymentDate,
    next_payment_date: nextPayment,
    user_id: userId,
    initial_sub_amount: initialsub,
  };
  // console.log(setdata, "What to pass");

  const [selectedUser, setSelectedUser] = useState("");
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCounters());
  }, [modalIsOpen]);
  useEffect(() => {
    const page = controller.page;
    const name = namefilter;
    const limit = controller.rowsPerPage;
    dispatch(getUsers({ page, name, limit }));
  }, [controller.page, namefilter, controller.rowsPerPage, modalIsOpen]);
  const formatToNaira = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };
  const setToPremium = () => {
    if (lastPaymentDate != "" && initialsub !== "" && nextPayment !== "") {
      dispatch(setAccountToPremium(setdata));
    } else {
      alert("Incomplete Data");
    }
  };

  const handleChange = (e) => {
    const value_ = e.target.value;
    setNameFilter(value_);
  };
  const openModal = (name, id) => {
    setIsOpen(true);
    setSelectedUser(name);
    setUserId(id);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // console.log(formatToNaira(5000)); // ₦5,000.00

  const freeUsers = useSelector(FU);
  const premiumUsers = useSelector(PU);
  const totalUsers = useSelector(TU);
  const income = useSelector(TI);
  const data = [
    {
      name: "Jan",
      premium: 4000,
      free: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      premium: 3000,
      free: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      premium: 2000,
      free: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      premium: 2780,
      free: 3908,
      amt: 2000,
    },
    {
      name: "May",
      premium: 1890,
      free: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      premium: 2390,
      free: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      premium: 3490,
      free: 4300,
      amt: 2100,
    },
  ];

  const data_b = [
    {
      subject: "Lagos",
      A: 120,
      B: 110,
      fullMark: 150,
    },
    {
      subject: "Ibadan",
      A: 98,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "Akure",
      A: 86,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "Ijesha",
      A: 99,
      B: 100,
      fullMark: 150,
    },
    {
      subject: "Abia",
      A: 85,
      B: 90,
      fullMark: 150,
    },
    {
      subject: "Awka",
      A: 65,
      B: 85,
      fullMark: 150,
    },
  ];

  return (
    <>
      <div className="px-5 mt-10 grid grid-cols-4 gap-5 font-sand">
        <div className="bg-white shadow-md rounded-md h-[100px] mb-10">
          <div className="flex flex-row px-2 mt-5 gap-2">
            <div className="h-14 w-14  rounded-md bg-red-400">
              <BsPeople size={40} color="white" />
            </div>
            <div>
              <p className="text-[14px] ">Total Registered Members</p>
              <p className="text-3xl font-semibold">{totalUsers}</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md h-[100px] mb-10">
          <div className="flex flex-row px-2 mt-5 gap-2">
            <div className="h-14 w-14  rounded-md bg-blue-400">
              <MdWorkspacePremium size={40} color="white" />
            </div>
            <div>
              <p className="text-[14px] ">Free Users</p>
              <p className="text-3xl font-semibold">{freeUsers}</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md h-[100px] mb-10">
          <div className="flex flex-row px-2 mt-5 gap-2">
            <div className="h-14 w-14  rounded-md bg-green-400">
              <MdWorkspacePremium size={40} color="white" />
            </div>
            <div>
              <p className="text-[14px] ">Premium Members</p>
              <p className="text-3xl font-semibold">{premiumUsers}</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md h-[100px] mb-10">
          <div className="flex flex-row px-2 mt-5 gap-2">
            <div className="h-14 w-14 rounded-md bg-indigo-400">
              <GiTakeMyMoney size={40} color="white" />
            </div>
            <div>
              <p className="text-[14px] ">Total Income</p>
              <p className="text-3xl font-semibold">{formatToNaira(income)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Sections... */}
      <div className="px-5 font-sand text-[15px]">
        <h1 className="uppercase font-semibold mt-5 mb-5">Growth analysis</h1>
        <div className="grid grid-cols-2 gap-5">
          <div className="h-[300px] shadow-sm bg-white mb-2 rounded-md ">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="free" fill="#ffb2d3" />
                <Bar dataKey="premium" fill="#96E095" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="h-[300px] bg-white sha">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data_b}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={30} domain={[0, 150]} />
                <Radar
                  name="Mike"
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
                <Radar
                  name="Lily"
                  dataKey="B"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  fillOpacity={0.6}
                />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      {/* Last section */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 className="font-sand text-sm font-bold">
          Edit payment information for {selectedUser}
        </h2>
        <button onClick={closeModal}>close</button>
        {/* <div>I am a modal</div> */}
        <div className="columns-2 font-sand text-sm">
          <div>
            <p>Enter last payment date</p>
            <input
              type="date"
              onChange={(e) => setLastPaymentDate(e.target.value)}
              className="mt-2"
              name=""
              id=""
            />
          </div>
          <div>
            <p>Enter next payment date</p>
            <input
              type="date"
              onChange={(e) => setNextPayment(e.target.value)}
              className="mt-2"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="font-sand text-sm mt-2">
          <label htmlFor="" className="text-red-800">
            Enter amount paid
          </label>{" "}
          <br />
          <input
            type="text"
            name=""
            onChange={(e) => setInitialSub(e.target.value)}
            className="mt-2 w-full"
            placeholder="0.00"
            id=""
          />
        </div>
        {/* <div className="text-sm font-sand">
          <label htmlFor="">Check this box if payment is for full term</label>{" "}
          <br />
          <input type="checkbox" className="h-7 w-7 rounded-md" name="" id="" />
        </div> */}
        <button
          onClick={setToPremium}
          className="w-full font-sand bg-preggifyGreen mt-2 text-white text-sm font-bold h-10 hover:bg-green-700"
        >
          Activate premium membership
          <DotLoader
            className="ml-5 py-2"
            color={"#ffffff"}
            loading={loading}
            // cssOverride={override}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </button>
        <p className="text-red-900  text-[10px]">
          Kindly be sure before you active membership tho. Tayo's hand is not
          dia!!
        </p>
      </Modal>
      <div className="mt-10 font-sand px-5 ">
        <input
          type="text"
          className="rounded-md"
          name=""
          onChange={(e) => handleChange(e)}
          placeholder="Type name to search"
          id=""
        />
        <table class="table my-6  w-full shadow-md bg-white rounded-md text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-white uppercase bg-[#ffb2d3]">
            <tr className="">
              <th scope="col" class="px-6 py-4">
                NAME
              </th>
              <th scope="col" class="px-6 py-4">
                email
              </th>
              <th scope="col" class="px-6 py-4">
                phone number
              </th>
              <th scope="col" class="px-6 py-4">
                Account status
              </th>
              <th scope="col" class="px-6 py-4">
                GENOTYPE
              </th>
              <th scope="col" class="px-6 py-4">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
            {listUsers.length == 0
              ? "No data"
              : listUsers.map((x) => {
                  return (
                    <tr>
                      <th class="flex gap-3 px-6 py-4 font-normal text-gray-900">
                        <div class="relative h-10 w-10 bg-pink-300 rounded-full">
                          {/* <img
                        class="h-full w-full rounded-full object-cover object-center"
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                          {/* <span class="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span> */}
                        </div>
                        <div class="text-sm">
                          <div class="font-medium text-gray-700">
                            {x.first_name}
                          </div>
                          <div class="text-gray-400">{x.last_name}</div>
                        </div>
                      </th>
                      {/* <td className="px-5 py-4"> {x.first_name}</td> */}
                      <td className="px-5 py-4">{x.email}</td>
                      <td className="px-5 py-4">{x.phone_number}</td>
                      <td className={"px-5 py-4"}>
                        <span
                          className={
                            x.subscription_type == "Free"
                              ? "h-10 py-2 px-5  rounded-[250px] text-white bg-red-400"
                              : "bg-preggifyGreen h-10 py-2 px-2 rounded-[250px] text-white"
                          }
                        >
                          {x.subscription_type}
                        </span>
                      </td>
                      <td className="px-5 py-4">
                        <span
                          className={
                            x.genotype == "AS" || x.genotype == "SS"
                              ? "h-10 py-2 px-2 rounded-full text-white bg-red-600"
                              : " h-10 py-2 px-2  text-black"
                          }
                        >
                          {x.genotype}
                        </span>
                      </td>
                      <td>
                        <div className="columns-3">
                          <div>
                            <AiFillEdit
                              size={20}
                              onClick={() => openModal(x.first_name, x._id)}
                            />
                          </div>
                          <div>
                            <LuView size={20} />
                          </div>
                          <p>c</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            {/* {} */}
          </tbody>
        </table>
        <TablePagination
          page={controller.page}
          count={totlpage}
          rowsPerPage={controller.rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Home;
