const SideBar = () => {
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <div className="bg-white h-screen w-[15rem] px-1 font-sand">
        <div className="  mt-5">
          <div className="w-20 rounded-md mx-5 h-16 bg-alternateGreen">
            <h1 className="text-white font-black px-1 mt-5 py-4">Preggify</h1>
          </div>
          <div className="px-5">
            <ul className="mt-5">
              <li className="mt-2 mb-2">Home</li>
              <li className="mt-2 mb-2">Finance</li>
              <li className="mt-2 mb-2">Upload content</li>
              <li className="mt-2 mb-2" onClick={handleLogout}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
