import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const getToks = sessionStorage.getItem("tokken");

const BASEURL_LIVE = 'https://stingray-app-a56hg.ondigitalocean.app/preggify/v1/'
const LOCAL = 'http://localhost:3000/preggify/v1/'
///live
const URL = `${BASEURL_LIVE}admin/counters`
const URL_B = `${BASEURL_LIVE}admin/userlist`
const URL_C = `${BASEURL_LIVE}admin/setaccounttoprem`
// const URL_C = `${BASEURL_LIVE}freetips`
// const URL_D = `${BASEURL_LIVE}getpaidtips`
// const URL_E = `${BASEURL_LIVE}getaffirmations`
// const URL_F = `${BASEURL_LIVE}managedepression`
// const URL_G = `${BASEURL_LIVE}gettest`
// const URL_H = `${BASEURL_LIVE}registerpartners`
// const URL_I = `${BASEURL_LIVE}updateprofile`
// const URL_J = `${BASEURL_LIVE}mysubdetails`
// const URL_K = `${BASEURL_LIVE}confirmsubscription`
// const URL_L = `${BASEURL_LIVE}getpregnancyvideos`
// const URL_M = `${BASEURL_LIVE}turnofsub`

export const getCounters = createAsyncThunk(
      "user/setlmp",
      async (args, { rejectWithValue },) => {
            try {
                  const { data } = await axios.get(URL, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  // console.log(data, "Shiiiiit")
                  return data
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);

export const getUsers = createAsyncThunk(
      "user/getUsersList",
      async (paramsObj = {}, { rejectWithValue }) => {
            // Construct the URL with the query parameters
            const params = new URLSearchParams();

            Object.keys(paramsObj).forEach(key => {
                  if (paramsObj[key]) {
                        params.append(key, paramsObj[key]);
                  }
            });

            const finalURL = params.toString() ? `${URL_B}?${params.toString()}` : URL_B;

            try {
                  const { data } = await axios.get(finalURL, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  // console.log(data, "Shiiiiit");
                  return data;
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
export const setAccountToPremium = createAsyncThunk(
      "user/setToPremium",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_C, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  alert("Account Updated")
                  return data;
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);

// export const getUsers = createAsyncThunk(
//       "user/usersList",
//       async (args, { rejectWithValue },) => {
//             // const { name, pageNumber, pageLimit, date } = args
//             // const params = new URLSearchParams();
//             // if (name) params.append('name', name);
//             // if (pageLimit) params.append('pageLimit', pageLimit);
//             // if (date) params.append('date', date);
//             // if (pageNumber) params.append('pageNumber', pageNumber);

//             // const finalURL = params.toString() ? `${URL_B}?${params.toString()}` : URL_B; // Check if any params were added
//             try {
//                   const { data } = await axios.get(URL_B, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   console.log(data, "Shiiiiit")
//                   return data
//             } catch (err) {
//                   console.log(err)
//                   rejectWithValue(err.response.data);
//             }
//       }
// );

// export const subscriptionDetails = createAsyncThunk(
//       "user/subscriptiondetails",
//       async (args, { rejectWithValue },) => {
//             try {
//                   const { data } = await axios.post(URL_J, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   // toast.success("Data Saved")
//                   if (data.data) {
//                         return data.data[0].payment_history
//                   }
//                   return data

//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// //update subscription details..
// export const update_subscription_status = createAsyncThunk(
//       "user/updatesubscriptiondetails",
//       async (args, { rejectWithValue },) => {
//             try {
//                   const { data } = await axios.put(URL_K, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   toast.success("Subscription updated")
//                   return data

//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// //add this..
// export const update_profile = createAsyncThunk(
//       "user/updateprofile",
//       async (args, { rejectWithValue },) => {
//             try {
//                   const { data } = await axios.put(URL_I, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   toast.success("profile updated")

//                   return data
//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );

// export const fetchProfile = createAsyncThunk(
//       "user/userData",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.post(URL_B, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   return data;
//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// export const fetchTips = createAsyncThunk(
//       "Tips/FreeTips",
//       async (args, { rejectWithValue }) => {
//             try {

//                   const { data } = await axios.post(URL_C, args);
//                   return data.data[0]
//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// export const fetchProTips = createAsyncThunk(
//       "Tips/proTips",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.post(URL_D, args);
//                   return data.data[0]
//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// export const addPartner = createAsyncThunk(
//       "partner/savePartner",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.post(URL_H, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   });
//                   // console.log("yep", data)
//                   toast.success(data.message)
//             } catch (err) {
//                   toast.warn("please retry")
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// export const fetchAffirmations = createAsyncThunk(
//       "Affirm/Affirmations",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.get(URL_E);
//                   return data.data[0]
//                   // console.log("affirmation", data.data[0])
//             } catch (err) {
//                   rejectWithValue(err.response.data);
//             }
//       }
// );
// export const setDepressiontest = createAsyncThunk(
//       "submit/DepressionTest",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.post(URL_F, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   })
//                   toast.success("Thanks for completing the test")
//                   return data
//             } catch (err) {
//                   // toast.warn("please retry")
//                   rejectWithValue(err.resonse.data)
//             }
//       })
// export const getpregnancyvideos = createAsyncThunk(
//       "get/pregnancyvideos",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.get(URL_L, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   })
//                   // toast.success("Thanks for completing the test")
//                   return data
//             } catch (err) {
//                   // toast.warn("please retry")
//                   rejectWithValue(err.resonse.data)
//             }
//       })
// //changes 
// export const testResult = createAsyncThunk(
//       "getTest/TestReult",
//       async (args, { rejectWithValue }) => {
//             try {

//                   const { data } = await axios.get(`${URL_G}` + '/?user_id=' + args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   })
//                   return data.data[0].Detector_test

//             } catch (err) {
//                   // toast.warn("please retry")
//                   rejectWithValue(err.resonse.data)
//             }
//       })
// export const check_sub = createAsyncThunk(
//       "managesub/Subscriptions",
//       async (args, { rejectWithValue }) => {
//             try {
//                   const { data } = await axios.put(URL_M, args, {
//                         headers: {
//                               Authorization: `Bearer ${JSON.parse(getToks)}`
//                         }
//                   })
//                   return data
//             } catch (err) {
//                   // toast.warn("please retry")
//                   rejectWithValue(err.resonse.data)
//             }
//       })

const adminSlice = createSlice({
      name: "adminprofile",
      initialState: {

            totalUsers: "",
            freeUsers: "",
            premiumUsers: "",
            income: "",
            loading: false,
            success: false,
            currentPage: "",
            limit: "",
            users: "",
            totalCount: ""

      },
      // reducers: {
      //       setID: (state, action) => {
      //             state.id = action.payload
      //       },

      //       setmenu: (state, action) => {
      //             state.menu_status = !state.menu_status
      //       },
      //       setmenuMobile: (state) => {
      //             state.menu_status = false
      //       },
      //       setGA: (state, action) => {
      //             const { GA } = action.payload
      //             state.GA = action.payload
      //       },
      //       setEDD: (state, action) => {
      //             state.EDD = action.payload
      //       },
      //       SetTrimester: (state, action) => {
      //             state.trimester = action.payload
      //       },
      //       closeModal: (state, action) => {
      //             state.isOpen = action.payload
      //       },
      //       setPremium: (state, action) => {
      //             state.subscription_type = "premium"
      //       },
      //       setFree: (state, action) => {
      //             state.subscription_type = "Free"
      //       }
      //       // setLMP:(state,action)
      // },
      extraReducers: (builder) => {
            builder.addCase(getCounters.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(getCounters.fulfilled, (state, action) => {
                  const { total_members, premium_users, free_users, total_income } = action.payload
                  state.income = total_income
                  state.freeUsers = free_users
                  state.premiumUsers = premium_users
                  state.totalUsers = total_members
                  state.loading = false
            })
            builder.addCase(getCounters.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })

            builder.addCase(getUsers.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(getUsers.fulfilled, (state, action) => {
                  const { data, current_page, total_count, limit } = action.payload
                  state.users = data
                  state.currentPage = current_page
                  state.limit = limit
                  state.totalCount = total_count
                  state.loading = false
                  state.success = true
            })
            builder.addCase(getUsers.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(setAccountToPremium.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(setAccountToPremium.fulfilled, (state, action) => {
                  state.loading = false
                  state.success = true
            })
            builder.addCase(setAccountToPremium.rejected, (state, action) => {
                  state.loading = false
                  state.success = false
            })
      }
})


export const TU = (state) => state.admin.totalUsers
export const FU = (state) => state.admin.freeUsers
export const PU = (state) => state.admin.premiumUsers
export const TI = (state) => state.admin.income
export const loadIndicator = (state) => state.admin.loading
export const usersList = (state) => state.admin.users
export const current = (state) => state.admin.currentPage
export const total = (state) => state.admin.totalCount
export const pageLim = (state) => state.admin.limit
// export const test = (state) => state.user.loading

export default adminSlice.reducer
//export const { setID, setEDD, setGA, SetTrimester, setmenu, setmenuMobile, closeModal, setPremium, setFree } = adminSlice.actions