import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";

const BASEURL_LIVE = 'https://stingray-app-a56hg.ondigitalocean.app/preggify/v1/'
// const LOCAL = 'http://localhost:3000/preggify/v1/'
const URL = `${BASEURL_LIVE}adminlogin`
// const URL_B = `${LOCAL}login/finalize`
// const URL_C = `${LOCAL}login/resendotp`

export const logMeIn = createAsyncThunk(
      "login/adminlogin",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL, args);
                  // toast.info(data.message)
                  console.log(data)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);


const loginSlice = createSlice({
      name: "login",
      initialState: {
            loading: false,
            success: false,
            token: "",
            loadingB: false,
            passMessage: "",

      },
      reducers: {},
      extraReducers: (builder) => {
            builder.addCase(logMeIn.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(logMeIn.fulfilled, (state, action) => {
                  const { message, data } = action.payload
                  state.success = true
                  state.loading = false
                  state.passMessage = message
                  state.token = data
                  // state._id=id
            })
            builder.addCase(logMeIn.rejected, (state) => {
                  state.success = false
                  state.loading = false
            })
            // builder.addCase(resendOTP.rejected, (state) => {
            //       state.success = false
            //       state.loadingB = false
            // })
      }
})

export const toks = (state) => state.login.token
export const loader = (state) => state.login.loading

export default loginSlice.reducer

// export const { Subscription, plan } = registrtaionSlice.actions