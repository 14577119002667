import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./reducers/login";
import adminReducer from "./reducers/admin";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import Modal from "react-modal";

import { PersistGate } from "redux-persist/integration/react";

const rootReducer = combineReducers({
  // register: registrationReducer,
  login: loginReducer,
  admin: adminReducer,
});
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["login"],
  // sessionStorage,
};
//
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // // Ignore these paths in the state
        // ignoredPaths: ["items.dates"],
      },
    }),
});
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
Modal.setAppElement("#t-modal");
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* <ToastContainer /> */}

        <App />
      </BrowserRouter>{" "}
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
