import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logMeIn, toks } from "../reducers/login";

const Login = () => {
  const dispatch = useDispatch();
  const toks_ = useSelector(toks);

  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(logMeIn({ email, password }));
  };
  console.log(toks_, "toks");

  useEffect(() => {
    if (toks_) {
      sessionStorage.setItem("tokken", JSON.stringify(toks_));
      window.location.href = "/admin/Dashboard";
    }
  }, [toks_]);

  return (
    <>
      <div className="flex items-center justify-center flex-row font-sand bg-pink-50 h-full">
        <div className="bg-white h-[600px] w-96 my-20 rounded-sm ">
          <div className="px-10">
            <div>
              <h1 className="font-sand text-preggifyPurple font-black text-xl mt-10 text-center uppercase">
                Admin Portal
              </h1>
              <p className="font-sand text-sm mt-2">
                Enter your login details to login as an admin
              </p>
              <label htmlFor=""></label>
              <input
                type="text"
                className="w-full font-sand text-sm mt-10 mb-5 rounded-md"
                placeholder="email@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                // onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="w-full rounded-md"
                placeholder="******"
                onChange={(e) => setPass(e.target.value)}
              />
              <button
                className="mt-10 bg-preggifyPurple w-full text-white h-10 rounded-sm"
                onClick={handleLogin}
              >
                Submit
              </button>
              <p className="text-sm text-preggifyPurple mt-2">
                Dont forget your password
              </p>
            </div>
          </div>
        </div>
        <div className="h-[600px] w-96 my-20 shadow-md bg-gradient-to-br from-transparent to-[#37032ad6] bg-preggifyPurple ">
          <div className="px-20 mt-10">
            <h1 className="text-center font-sand text-white uppercase">
              Welcome
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
