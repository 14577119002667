import { Outlet } from "react-router-dom";
import SideBar from "./sidebar";
import { Navigate } from "react-router-dom";

const tokks = sessionStorage.getItem("tokken")?.toString();
const Layout = () => {
  let auth = { tokken: tokks };
  return auth.tokken ? (
    <>
      <div className="grid">
        <div className="col-start-1 sm:z-10 sm:basis-0 lg:overflow-auto sm:overflow-y-hidden absolute">
          <SideBar />
        </div>
        <div className="lg:ml-[240px] sm:col-start-1 lg:col-span-2 bg-[#f3f3f9] px-15">
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default Layout;
