import "./App.css";
import Login from "./components/login";
import Layout from "./components/adminLayout";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />{" "}
        <Route path="/admin" element={<Layout />}>
          <Route index path="/admin/Dashboard" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
